import Vue from 'vue'
import Router from 'vue-router'
import layoutRouters from './layout.router'
import selfCenterRouters from './self-center.router'
import warehouseRouters from './warehouse.router'
import informationRouters from './information.router'
import equipmentRouters from './equipment.router'
import qualityRouters from './quality.router'
import settingRouters from './setting.router'
import planRouters from './plan.router'
import siteWarehouseRouters from './site-warehouse.router'
import statisticsBoardRouters from './statistics-board.router'
import callRouters from './call.router'
import applyButtons from './page-buttons/apply.buttons'
import facilityRouters from './facility.router'
import serviceRouters from './service.router'
import salesRouters from './sales.router'
import purchaseManageRoutes from './purchase-manage.router'
import project from './project.router'
import mould from './mould.router'
import barcodeRouter from './barcode.router'
import qualityManagement from './qualityManagement.router'
import projectManagement from './projectManagement.router'
import changeManagement from './changeManagement.router'
import statisticsStatementRouters from './statistics-statement.router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import Blank from '@/layout/blank'
import { treeToArr, generateFlatRoutes } from '@/utils'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
    role: 'roleCode'
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const baseRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/uploadForProcess',
    name: 'uploadForProcess',
    meta: { title: '异常流程文件', noAuth: true, noCache: false },
    specFlag:1,
    component: () => import('@/views/uploadForProcess'),
    hidden: true
  },
  {
    path: '/memberRouteSetting',
    name: 'memberRouteSetting',
    meta: { title: '路由管理', noAuth: true, noCache: false },
    specFlag:1,
    component: () => import('@/views/routeSetForAllMember'),
    hidden: true
  },
  {
    path: '/memberManagement',
    name: 'memberManagement',
    meta: { title: '租户管理', noAuth: true, noCache: false },
    specFlag:1,
    component: () => import('@/views/memberManagement.vue'),
    hidden: true
  },
  {
    path: '/bind',
    name: 'gogogo',
    specFlag:1,
    component: () => import('@/views/bind.vue'),
    hidden: true
  },
  {
    path: '/energy-board',
    name: 'test',
    specFlag:1,
    component: () => import('@/views/test.vue'),
    hidden: true
  },
  {
    path: '/uploadVideo',
    name: 'uploadVideo',
    meta: { title: '上传视频', noAuth: true, noCache: false },
    component: () => import('@/views/home/uploadVideo'),
    hidden: true
  },
  {
    path: '/tenant',
    name: 'tenant',
    meta: { title: '租户列表', noAuth: true, noCache: false },
    component: () => import('@/views/tenant/tenant'),
    hidden: true
  },
  {
    path: '/tenantDetail',
    name: 'tenantDetail',
    meta: { title: '租户详情', noAuth: true, noCache: false },
    component: () => import('@/views/tenant/detail'),
    hidden: true
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { title: '看板', noAuth: true, noCache: false },
    component: Blank,
    children: [
      {
        path: 'kiki/:id(\\d+)',
        name: 'kiki',
        component: () => import('@/views/dashboard/bbelc/board.vue'),
        meta: { title: '奇奇看板', noAuth: true, noCache: true }
      },
      {
        path: 'reagold/:id(\\d+)',
        name: 'reagold',
        component: () => import('@/views/dashboard/reagold/board.vue'),
        meta: { title: '瑞鼎看板', noAuth: true, noCache: true }
      }
    ]
  }
]
export const mainRoutes = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    meta: { title: '', icon: 'equipment', activeMenu: '/' },
    redirect: '/home',
    children: [
      {
        path: 'refresh',
        name: 'refresh',
        component: () => import('@/layout/refresh'),
        meta: { title: '刷新', noAuth: true },
        hidden: true
      },

      layoutRouters,
      // // 应用
      // {
      //   path: '/apply',
      //   name: 'applyMarket',
      //   component: () => import('@/views/apply/index'),
      //   meta: { title: '应用市场', noCache: true, activeMenu: 'applyMarket', buttons: applyButtons.applyMarket('applyMarket'), hiddenSiderbar: true }
      //   // hidden: true
      // },
      // // 应用详情
      // {
      //   path: '/applyDetails',
      //   name: 'applyDetails',
      //   component: () => import('@/views/apply/applyDetails'),
      //   meta: { title: '应用市场-详情', noCache: true, activeMenu: 'applyDetails', buttons: applyButtons.applyDetails('applyDetails'), hiddenSiderbar: true }
      //   // hidden: true
      // },
      statisticsBoardRouters,
      projectManagement,
      salesRouters,
      project,
      planRouters,
      changeManagement,

      purchaseManageRoutes,
      warehouseRouters,
      // qualityManagement,
      qualityRouters,
      callRouters,
      equipmentRouters,
      mould,
      serviceRouters,
      barcodeRouter,
      // siteWarehouseRouters,
      // facilityRouters,
      informationRouters,
      settingRouters,
      selfCenterRouters
    ]
  }

]
export const spectRoutes = [
  {
    path: '/auth-dashboard',
    name: 'authDashboard',
    meta: { title: '北变看板' },
    component: Blank,
    children: [
      {
        path: 'bbelc/:id(\\d+)',
        name: 'bbelc',
        component: () => import('@/views/dashboard/bbelc/board.vue'),
        meta: { title: '北变看板', noAuth: true, noCache: true }
      }
    ]
  },
  {
    path: '/board-detail/:id(\\d+)',
    name: 'boardDetail',
    component: () => import('@/views/statistics-board/board-detail/board-detail.vue'),
    meta: { title: '看板', noAuth: true, noCache: true }
  },

  {
    path: '/dataease',
    name: 'dataease',
    component: () => import('@/views/statistics-board/test.vue'),
    meta: { title: '测试000', noAuth: true, noCache: true }
  },

  {
    path: '/workshop-board-detail/:type(\\d+)',
    name: 'workshopBoardDetail',
    component: () => import('@/views/statistic-statement/workshop-screen/boardDetail.vue'),
    meta: { title: '车间大屏', noAuth: true, noCache: true }
  },
  {
    path: '/reHome/:memberCode/:login',
    name: 'reHome',
    component: () => import('@/views/reHome'),
    meta: { title: '', noCache: true, noAuth: true }
    // hidden: true
  },
  {
    path: '/reConsole/:memberCode',
    name: 'reConsole',
    component: () => import('@/views/console'),
    meta: { title: '', noCache: true, noAuth: true }
    // hidden: true
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

export const routesList = [...baseRoutes, ...mainRoutes, ...spectRoutes]
export const constantRoutes = mainRoutes[0].children
export const allRouters = treeToArr(constantRoutes)
// export const breadRouters = generateFlatRoutes(constantRoutes)

// console.log('菜单路由', routesList)
const routess = [...baseRoutes, ...generateFlatRoutes(mainRoutes), ...spectRoutes]
// console.log('三级处理成二级路由', generateFlatRoutes(mainRoutes))
// console.log('处理后的路由', routess)
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routess
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
