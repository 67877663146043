import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import { getFirstRouterName, treeToArr } from '@/utils' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { baseRoutes } from './router'
import Vue from 'vue'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = treeToArr(baseRoutes).map(item => item.name) // no redirect whitelist
// 路由变化时将上一个页面的所有请求中的请求取消
router.beforeEach(async(to, from, next) => {
  while (Vue.Cancel.length > 0) {
    Vue.Cancel.shift()('cancel')
  }
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  const hasToken = getToken()
  if (to.name === 'reHome' || to.name === 'reConsole' || to.specFlag ) {
    next()
  } else {
    if (hasToken) {
      if (to.path === '/login') {
        // if is logged in, redirect to the home page
        next({ name: getFirstRouterName() })
        NProgress.done()
      } else {
        const hasGetUserInfo = store.getters.name
        if (hasGetUserInfo) {
          if (hasAuth(to)) {
            next()
          } else {
            next('/404')
          }
        } else {
          try {
            if(store.getters.priorityList.length === 0){
              await store.dispatch('user/priorityStatusList')
            }
            // get user info
            await store.dispatch('user/getInfo')
            if (hasAuth(to)) {
              next()
            } else {
              next('/404')
            }
          } catch (error) {
            // remove token and go to login page to re-login
            await store.dispatch('user/resetToken')
            Message.error(error || 'Has Error')
            next('/login')
            NProgress.done()
          }
        }
      }
    } else {
      /* has no token*/
      if (whiteList.includes(to.name)) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next({
          path: '/login',
          query: { redirect: to.fullPath } // 将目标路由的路径作为参数传递给登录页面
        });
        NProgress.done()
      }
    }
  }
})

router.onError((error) => {
  const pattern = /Loading chunk (.)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    window.location.reload()
  }
})

// 判断是否有权限可以进入
const hasAuth = (to) => {
  //新增加的判断是为了处理首页新增路由  但是admin redis存的路由没有自动刷新  需要手动处理权限
  return store.state.user.roles.indexOf(to.name) !== -1 || whiteList.indexOf(to.name) !== -1 || to.meta.noAuth
  || to.name.startsWith("Home");
}
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
