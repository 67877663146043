import planButtons from './page-buttons/plan-manage.buttons'
import informationButtons from './page-buttons/information.buttons'
export default {
  path: 'project',
  name: 'project',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '计划管理', icon: require('@/assets/l_c_S_images/计划管理.svg'), activeMenu: 'project' },
  redirect: '/project/mrp',
  children: [
    // {
    //   path: 'mrp',
    //   name: 'mrp',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: 'MRP', activeMenu: 'mrp' },
    //   redirect: '/project/mrp/purchasing-demand',
    //   children: [
        // {
        //   path: 'production-demand',
        //   name: 'ProductionDemand',
        //   component: () => import('@/layout/blank.vue'),
        //   redirect: '/project/mrp/production-demand/list',
        //   meta: { noCache: false, title: '生产需求', activeMenu: 'production-demand', canLink: true },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'ProductionDemandList',
        //       component: () => import('@/views/project/MRP/productionDemand/list.vue'),
        //       meta: { noCache: false, title: '生产需求列表', activeMenu: 'production-demand',buttons: planButtons.productionDemandList('productionDemandList')},
        //       hidden: true
        //     },
        //     {
        //       path: 'module-matching',
        //       name: 'ModuleMatching',
        //       component: () => import('@/views/project/MRP/productionDemand/module-matching.vue'),
        //       meta: { noCache: true, title: '模组匹配', activeMenu: 'production-demand', nb: true },
        //       hidden: true
        //     }
        //   ]
        // },
        // {
        //   path: 'materials-production',
        //   name: 'MaterialsProduction',
        //   component: () => import('@/views/project/MRP/materialsProduction/index.vue'),
        //   meta: { noCache: true, title: '物料生产', activeMenu: 'materials-production', buttons: planButtons.productionDemand('MaterialsProduction') }
        // },
        // {
        //   path: 'production-task',
        //   name: 'ProductionTask',
        //   component: () => import('@/views/project/MRP/productionDemand/productionTask.vue'),
        //   meta: { noCache: true, title: '生产任务', activeMenu: 'production-task', buttons: planButtons.productionTask('productionTask') }
        // },
        // {
        //   path: 'add-production-task',
        //   name: 'AddProductionTask',
        //   component: () => import('@/views/project/MRP/materialsProduction/addProductionTask.vue'),
        //   meta: { noCache: true, title: '新增生产任务', activeMenu: 'materials-production', buttons: planButtons.planManage('planManage') },
        //   hidden: true
        // },
        // {
        //   path: 'automatic-scheduling',
        //   name: 'AutomaticScheduling',
        //   component: () => import('@/views/project/MRP/materialsProduction/automaticScheduling.vue'),
        //   meta: { noCache: true, title: '自动排程', activeMenu: 'materials-production', buttons: planButtons.planManage('planManage') },
        //   hidden: true
        // },
        // {
        //   path: 'purchasing-demand',
        //   name: 'purchasingDemand',
        //   component: () => import('@/views/project/MRP/index.vue'),
        //   meta: { noCache: true, title: '采购需求', activeMenu: 'purchasing-demand', buttons: planButtons.planManage('planManage') }
        // },
        // {
        //   path: 'material-purchase',
        //   name: 'materialPurchase',
        //   component: () => import('@/views/project/MRP/materialPurchase.vue'),
        //   meta: { noCache: true, title: '物料请购', activeMenu: 'material-purchase', buttons: planButtons.materialPurchase('materialPurchase') }
        // },
        // {
        //   path: 'purchase-list',
        //   name: 'purchaseList',
        //   component: () => import('@/views/project/MRP/purchaseList.vue'),
        //   meta: { noCache: true, title: '请购单', activeMenu: 'purchase-list', buttons: planButtons.purchaseList('purchaseList') }
        // },
        // {
        //   path: 'purchase-exception',
        //   name: 'PurchaseException',
        //   component: () => import('@/views/project/MRP/purchaseException.vue'),
        //   meta: { noCache: true, title: '采购异常', activeMenu: 'purchase-exception', buttons: planButtons.purchaseException('purchaseException') }
        // },
        // {
        //   path: 'purchase-detail',
        //   name: 'purchaseDetail',
        //   component: () => import('@/views/project/MRP/purchaseDetail.vue'),
        //   meta: { noCache: true, title: '请购详情', activeMenu: 'purchase-detail' },
        //   hidden: true
        // },
        // {
        //   path: 'mrp-setting',
        //   name: 'mrpSetting',
        //   component: () => import('@/views/project/MRP/mrpSetting.vue'),
        //   meta: { noCache: true, title: 'MRP设置', activeMenu: 'mrp-setting' }
        // },
        // {
        //   path: 'project-management',
        //   name: 'ProjectManagement',
        //   component: () => import('@/layout/blank.vue'),
        //   redirect: '/project/mrp/project-management/list',
        //   meta: { noCache: false, title: '项目管理', activeMenu: 'project-management', canLink: true },
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'ProjectManagementList',
        //       component: () => import('@/views/project/MRP/projectManagement/list.vue'),
        //       meta: { noCache: false, title: '项目列表', activeMenu: 'project-management'},
        //       hidden: true
        //     },
        //     {
        //       path: 'detail',
        //       name: 'ProjectManagementDetail',
        //       component: () => import('@/views/project/MRP/projectManagement/detail/detail.vue'),
        //       meta: { noCache: true, title: '项目详情', activeMenu: 'project-management', nb: true },
        //       hidden: true
        //     }
        //   ]
        // },
    //   ]
    // },
    {
      path: 'materialsBalance',
      name: 'materialsBalance',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '物料平衡', activeMenu: 'materialsBalance' },
      children: [
        {
          path: 'materials-balance-list',
          name: 'materialsBalanceList',
          component: () => import('@/views/information/materials-balance/materials-balance.vue'),
          meta: { title: '物料平衡表', activeMenu: 'materials-balance-list', buttons: informationButtons.materialsBalance('materialsBalanceList')}
        },
        {
          path: 'materials-require',
          name: 'materialsRequire',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '需求单物料表', activeMenu: 'materials-require', canLink: true },
          redirect: '/project/materialsBalance/materials-require/list',
          children: [
            {
              path: 'list',
              name: 'materialsRequireList',
              component: () => import('@/views/information/materials-require/materials-require.vue'),
              meta: { title: '需求单列表', activeMenu: 'materials-require', buttons: informationButtons.materialsRequireList('materialsRequireList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'materialsRequireDetail',
              component: () => import('@/views/information/materials-require/detail/detail.vue'),
              meta: { title: '需求单详情', noCache: true, activeMenu: 'materials-require', nb: true,buttons: informationButtons.materialsRequireDetail('materialsRequireDetail') },
              hidden: true
            }
          ]
        },
        // {
        //   path: 'materials-apply',
        //   name: 'materialsApply',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '领退料单', activeMenu: 'materials-apply', canLink: true },
        //   redirect: '/project/materialsBalance/materials-apply/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'materialsApplyList',
        //       component: () => import('@/views/information/materials-apply/materials-apply.vue'),
        //       meta: { title: '领退料单列表', activeMenu: 'materials-apply', buttons: informationButtons.materialsApplyList('materialsApplyList')},
        //       hidden: true
        //     },
        //     {
        //       path: 'detail',
        //       name: 'materialsApplyDetail',
        //       component: () => import('@/views/information/materials-apply/detail/detail.vue'),
        //       meta: { title: '领料单详情', noCache: true, activeMenu: 'materials-apply', nb: true, buttons: informationButtons.materialsApplyDetail('materialsApplyDetail') },
        //       hidden: true
        //     },
        //     {
        //       path: 'return-detail',
        //       name: 'materialsApplyReturnDetail',
        //       component: () => import('@/views/information/materials-apply/detail/return-detail.vue'),
        //       meta: { title: '退料单详情', noCache: true, activeMenu: 'materials-apply', nb: true,buttons: informationButtons.materialsApplyReturnDetail('materialsApplyReturnDetail') },
        //       hidden: true
        //     },
        //     {
        //       path: 'batch-apply',
        //       name: 'materialsBatchApply',
        //       component: () => import('@/views/information/materials-apply/detail/batch-apply.vue'),
        //       meta: { title: '批量领料', noCache: true, activeMenu: 'materials-apply', nb: true},
        //       hidden: true
        //     },
        //   ]
        // },
      ]
    }
  ]
}
